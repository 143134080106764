<template>
    <div v-click-outside="closeDropdown" class="options">
        <kw-button button-type="secondary" prepend-icon-class="kw kw-grid" @click="toggleDropdown" />

        <custom-dropdown-body ref="optionsDropdown" position="bottom-right">
            <div class="options-list">
                <div v-if="isLoading" class="loader-wrapper">
                    <loader />
                </div>
                <p class="title">{{$t('available-charts')}}</p>

                <div v-for="(item, key) in optionsList" class="list-item">
                    <toggler
                      :toggler="item.visible"
                      @toggler_click="toggleOption(key, !item.visible)"
                      :disabled="getIsFreePlan && key === 'sov'"
                    />

                    {{ $t(`available-chart-titles.${key}`) }}
                </div>
            </div>
        </custom-dropdown-body>
    </div>

</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'OverviewOptions',
        computed: {
            ...mapGetters([
                'getProjectOverviewOptions',
                'getCurrentProject',
                'getActiveTag',
                'getIsFreePlan'
            ]),
            optionsList(){
                const { visibility_and_estimated_traffic, ...restSettings } = this.getProjectOverviewOptions;
                return restSettings;
            }
        },
        data(){
            return {
                isLoading: false,
            }
        },
        watch: {
            getCurrentProject: function(to){
                if(Object.keys(to?.project_overview_settings).length > 0) {
                    // this.setProjectOverviewOptions(
                    //     {...to.project_overview_settings,
                    //         visibility_and_estimated_traffic: {
                    //             ...(to.project_overview_settings.visibility_and_estimated_traffic ? to.project_overview_settings.visibility_and_estimated_traffic : {}),
                    //             visible: false,
                    //             order: 99,
                    //         },
                    //     }
                    // );
                    this.setProjectOverviewOptions({
                        project_metrics: to.project_overview_settings.project_metrics,
                        keywords_in_top: to.project_overview_settings.keywords_in_top,
                        sov: to.project_overview_settings.sov,
                        distribution: {
                            ...(to.project_overview_settings.distribution ? 
                                to.project_overview_settings.distribution : 
                                {   
                                    visible: true,
                                    order: 3
                                }
                            ),
                        },
                        visibility: to.project_overview_settings.visibility,
                        estimated_traffic: to.project_overview_settings.estimated_traffic,
                        top_pages: to.project_overview_settings.top_pages,
                        organic_traffic_acquisition: to.project_overview_settings.organic_traffic_acquisition,
                        serp_feature_performance: to.project_overview_settings.serp_feature_performance,
                        keywords_by_intent: to.project_overview_settings.keywords_by_intent,
                        average_rank: to.project_overview_settings.average_rank,
                        visibility_and_estimated_traffic: {
                            ...(to.project_overview_settings.visibility_and_estimated_traffic ? to.project_overview_settings.visibility_and_estimated_traffic : {}),
                            visible: false,
                            order: 99,
                        },
                    });
                } else {
                    this.resetProjectOverviewOptionsState();
                }
            }
        },
        methods: {
            ...mapActions([
                'setProjectOverviewOptionIsVisible', 
                'setProjectOverviewOptions',
                'resetProjectOverviewOptionsState',
                'resetProjectOverviewData'
            ]),
            toggleDropdown () {
                this.$refs.optionsDropdown.toggleDropdownOpen();
            },
            closeDropdown () {
                if(this.$refs.optionsDropdown?.getIsDropdownOpened) {
                    this.$refs.optionsDropdown.closeDropdown();
                }
            },
            toggleOption (key, value) {
                this.isLoading = true;
                this.setProjectOverviewOptionIsVisible({ projectId: this.getCurrentProject.id, option: key, value })
                .finally(()=>{
                    this.isLoading = false;
                });
            }
        },
        mounted(){
            this.resetProjectOverviewData();
            if(this.getCurrentProject&& Object.keys(this.getCurrentProject.project_overview_settings).length > 0) {
                // this.setProjectOverviewOptions(
                //     {...this.getCurrentProject.project_overview_settings,
                //         visibility_and_estimated_traffic: {
                //             ...(this.getCurrentProject.project_overview_settings.visibility_and_estimated_traffic ? this.getCurrentProject.project_overview_settings.visibility_and_estimated_traffic : {}),
                //             visible: false,
                //             order: 99,
                //         },
                //     }
                // );
                this.setProjectOverviewOptions({
                    project_metrics: this.getCurrentProject.project_overview_settings.project_metrics,
                    keywords_in_top: this.getCurrentProject.project_overview_settings.keywords_in_top,
                    sov: this.getCurrentProject.project_overview_settings.sov,
                    distribution: {
                        ...(this.getCurrentProject.project_overview_settings.distribution ? 
                            this.getCurrentProject.project_overview_settings.distribution : 
                            {   
                                visible: true,
                                order: 3
                            }
                        ),
                    },
                    visibility: this.getCurrentProject.project_overview_settings.visibility,
                    estimated_traffic: this.getCurrentProject.project_overview_settings.estimated_traffic,
                    top_pages: this.getCurrentProject.project_overview_settings.top_pages,
                    organic_traffic_acquisition: this.getCurrentProject.project_overview_settings.organic_traffic_acquisition,
                    serp_feature_performance: this.getCurrentProject.project_overview_settings.serp_feature_performance,
                    keywords_by_intent: this.getCurrentProject.project_overview_settings.keywords_by_intent,
                    average_rank: this.getCurrentProject.project_overview_settings.average_rank,
                    visibility_and_estimated_traffic: {
                        ...(this.getCurrentProject.project_overview_settings.visibility_and_estimated_traffic ?
                            this.getCurrentProject.project_overview_settings.visibility_and_estimated_traffic :
                            {}
                        ),
                        visible: false,
                        order: 99,
                    },
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .options {
        .kw-button {
            width: 36px;
            height: 36px;

            /deep/.kw {
                font-weight: 700;
            }
        }

        .options-list {
            position: relative;
            min-width: 247px;
            padding: 24px 16px;
            background: kw-color(kw-white, 1);

            .title{
                font-family: Roboto;
                font-size: 14px;
                font-weight: 500;
                line-height: 16px;
                margin-bottom: 16px;
            }

            .list-item{
                display: flex;
                gap: 8px;
                align-items: center;
                margin-bottom: 10px;
                font-family: Roboto;
                font-size: 13px;
                font-weight: 500;
                line-height: 20px;

                &:last-child{
                    margin-bottom: 0;
                }

                .toggler {
                    position: relative;
                }
            }
            .loader-wrapper{
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(255,255,255,.5);
            }
        }
    }
</style>
